body {
    margin: 0;
    font-family: "Inter", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

[class^="number-slide"],
[class*=" number-slide"] {
    background: grey;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 50px;
    color: #fff;
    font-weight: 500;
    height: 200px;
    max-height: 100vh;
}

.number-slide1 {
    background: rgb(64, 175, 255);
    background: linear-gradient(
                    128deg,
                    rgba(64, 175, 255, 1) 0%,
                    rgba(63, 97, 255, 1) 100%
    );
}

.number-slide2 {
    background: rgb(255, 75, 64);
    background: linear-gradient(
                    128deg,
                    rgba(255, 154, 63, 1) 0%,
                    rgba(255, 75, 64, 1) 100%
    );
}

.number-slide3 {
    background: rgb(182, 255, 64);
    background: linear-gradient(
                    128deg,
                    rgba(182, 255, 64, 1) 0%,
                    rgba(63, 255, 71, 1) 100%
    );
    background: linear-gradient(
                    128deg,
                    rgba(189, 255, 83, 1) 0%,
                    rgba(43, 250, 82, 1) 100%
    );
}

.number-slide4 {
    background: rgb(64, 255, 242);
    background: linear-gradient(
                    128deg,
                    rgba(64, 255, 242, 1) 0%,
                    rgba(63, 188, 255, 1) 100%
    );
}

.number-slide5 {
    background: rgb(255, 64, 156);
    background: linear-gradient(
                    128deg,
                    rgba(255, 64, 156, 1) 0%,
                    rgba(255, 63, 63, 1) 100%
    );
}

.number-slide6 {
    background: rgb(64, 76, 255);
    background: linear-gradient(
                    128deg,
                    rgba(64, 76, 255, 1) 0%,
                    rgba(174, 63, 255, 1) 100%
    );
}

.thumbnail .keen-slider__slide {
    font-size: 30px;
    margin-top: 10px;
    height: 100px;
}
.thumbnail .keen-slider__slide {
    cursor: pointer;
}
.thumbnail .keen-slider__slide.active {
    border: 2px dashed black;
}

.slider {
    width: 100%;
    @media (min-width: 740px) {
        width: 50%;
    }
}
.splide__list img {
    @media (min-width: 740px) {
        height: 500px;
    }

    margin-left: 50px;
}