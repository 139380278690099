@import url('https://fonts.googleapis.com/css2?family=Oranienbaum&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jost:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jost&display=swap');
// font-family: 'Oranienbaum', serif;
// font-family: 'Jost', sans-serif;

body {
  margin: 0;
  color: #27282c;
  font-family: 'Jost', sans-serif;
}
