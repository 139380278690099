$time-changing-theme: 2s;

* {
  box-sizing: border-box;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  color: #27282c;

  transition-property: background-color, color;
  transition-duration: 0.5s;
}

.container--dark {
  background-color: #27282c;
  color: #ffffff;

  transition-property: background-color, color;
  transition-duration: 0.5s;
}

.container--fixed {
  overflow: hidden;
  height: 100vh;
  justify-content: flex-end;
}

.section-title {
  margin-top: 100px;
  font-family: 'Oranienbaum', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 55px;

  @media (min-width: 1300px) {
    font-size: 44px;
  }
}

#form_textarea {
  resize: none;
}

.theme-toggler {
  display: grid;
  grid-template-columns: 60px 60px;
  gap: 23px;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;

  input {
    display: none;
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    cursor: pointer;
  }
}

.lang-toggler {
  display: grid;
  grid-template-columns: 47px 47px;
  gap: 23px;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;

  input {
    display: none;
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 44px;
    cursor: pointer;
  }
}

.theme-toggler--current,
.lang-toggler--current {
  border: 1px solid #27282c;
  border-radius: 3px;
}

.theme-toggler--current--dark,
.lang-toggler--current--dark {
  border: 1px solid #ffffff;
  border-radius: 3px;
}
