.footer {
  width: 100%;
  padding: 110px 18px 104px 18px;

  @media (min-width: 480px) {
    padding: 110px 60px 104px 60px;
    width: 400px;
    margin: 0 auto;
  }

  @media (min-width: 740px) {
    padding: 110px 100px 104px 100px;
    width: 100%;
    margin: 0 0;
  }

  @media (min-width: 980px) {
    padding: 110px 150px 104px 150px;
    margin: 0 auto;
  }

  @media (min-width: 1550px) {
    padding: 110px 200px 104px 250px;
  }

  @media (min-width: 1850px) {
    padding: 110px 200px 104px 400px;
  }
}

.top-row {
  @media (min-width: 980px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 110px;
    column-gap: 235px;
  }

  @media (min-width: 1024px) {
    display: grid;
    justify-content: space-between;
    grid-template-columns: 50% max-content;
    gap: 0;
    margin-bottom: 25px;
  }

  @media (min-width: 1200px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    column-gap: 30px;
  }

  @media (min-width: 1800px) {
    justify-content: center;
    column-gap: 235px;
  }
}

.bottom-row {
  @media (min-width: 980px) {
    display: flex;
    flex-direction: row-reverse;
    column-gap: 64px;
    align-items: center;
    justify-content: flex-end;
  }
}

.footer_logo-wrapper {
  margin-bottom: 26px;
}

.footer_logo {
  width: 207px;
  height: 64px;
}

.footer_address-wrapper {
  margin-bottom: 50px;
}

.footer_address {
  margin: 0;
  display: block;
  font-family: 'Jost';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  white-space: pre-line;
}

.footer_nav-list-wrapper {
  margin-bottom: 64px;
}

.footer_nav-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  font-family: 'Jost';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 48px;
}

.footer_phone-number-wrapper {
  margin-bottom: 41px;
}

.footer_phone-number {
  margin: 0;
  font-family: 'Jost';
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 46px;
}

.footer_email-wrapper {
  margin-bottom: 64px;
}

.footer_email {
  margin: 0;
  font-family: 'Jost';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  white-space: pre-line;

  a {
    color: #27282c;
    text-decoration: underline;

    transition-property: color;
    transition-duration: 0.5s;
  }
}

.footer_email--dark {
  margin: 0;
  font-family: 'Jost';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  white-space: pre-line;

  a {
    color: #ffffff;
    text-decoration: underline;

    transition-property: color;
    transition-duration: 0.5s;
  }
}

.footer_theme-toggler {
  padding-bottom: 32px;
  position: relative;

  @media (min-width: 1000px) {
    padding-bottom: 0;
    padding-left: 64px;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 46px;
    height: 1px;
    background-color: #494949;

    @media (min-width: 1000px) {
      width: 1px;
      height: 45px;
    }
  }
}

.footer_agreement-wrapper {
  margin: 32px 0;

  @media (min-width: 1300px) {
    display: block;
    margin-right: 54px;
  }
}

.footer_agreement {
  margin: 0;
  font-family: 'Jost';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #27282c;
  text-decoration: underline;

  transition-property: color;
  transition-duration: 0.5s;
}

.footer_agreement--dark {
  margin: 0;
  font-family: 'Jost';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #ffffff;
  text-decoration: underline;

  transition-property: color;
  transition-duration: 0.5s;
}

.footer_company-info {
  margin: 0;
  font-family: 'Jost';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;

  a {
    color: #27282c;
    text-decoration: underline;
  }
}
