.service-section {
  padding: 0 0 150px 0;

  @media (min-width: 1300px) {
    padding: 0 0 200px 0;
  }
}

.service-section_title-wrapper {
  margin-bottom: 30px;

  @media (min-width: 1300px) {
    margin-bottom: 44px;
  }
}

.service-section_service-list {
  padding: 0;
  margin: 0;
  list-style: none;
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;
  row-gap: 25px;

  @media (min-width: 750px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: 1300px) {
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 38px;
    column-gap: 5px;
  }
}

.service-item {
  display: grid;
  grid-template-columns: 50px 1fr;
  gap: 24px;

  @media (min-width: 1300px) {
    grid-template-columns: 86px 1fr;
  }
}

.service-item_image-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  background-color: rgba(39, 40, 44, 0.05);

  @media (min-width: 1300px) {
    height: 86px;
  }

  transition-property: background-color;
  transition-duration: 0.5s;
}

.service-item_image-wrapper--dark {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  background-color: #222327;

  @media (min-width: 1300px) {
    height: 86px;
  }

  transition-property: background-color;
  transition-duration: 0.5s;
}

.service-item_image {
  width: 22px;
  height: 22px;

  @media (min-width: 1300px) {
    width: 38px;
    height: 38px;
  }
}

.service-item_text-wrapper {
  display: flex;
  align-items: center;
}

.service-item_text {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 22px;
  white-space: pre-line;

  @media (min-width: 1300px) {
    font-weight: 400;
    font-size: 24px;
    line-height: 26px;
  }
}
