.form {
  position: relative;
  padding: 0 0 154px 0;

  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 200vw;
    height: 1px;
    background-color: #cacaca;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);

    transition-property: background-color;
    transition-duration: 0.5s;
  }

  @media (min-width: 1850px) {
    padding: 10px 200px 200px 0;
  }
}

.form--dark {
  position: relative;
  padding: 0 0 154px 0;

  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 200vw;
    height: 1px;
    background-color: #494949;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);

    transition-property: background-color;
    transition-duration: 0.5s;
  }

  @media (min-width: 1850px) {
    padding: 0 200px 200px 0;
  }
}

.form_title-wrapper {
  margin-bottom: 50px;

  @media (min-width: 1300px) {
    margin-bottom: 53px;
  }
}

.form_inputs-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  gap: 30px;
  color: #27282c;

  @media (min-width: 1300px) {
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    column-gap: 64px;
  }

  input[type='text'],
  input[type='tel'],
  input[type='email'] {
    width: 100%;
    height: 42px;
    border: none;
    border-bottom: 1px solid #cacaca;
    padding: 0 0 20px 0;
    outline: none;
    font-family: 'Jost', sans-serif;
    font-size: 20px;
    font-weight: 400;
    font-style: normal;
    line-height: 22px;
    color: #27282c;
    background: none;

    @media (min-width: 1300px) {
      margin-bottom: 40px;
    }

    &::placeholder {
      font-family: 'Jost', sans-serif;
      font-size: 20px;
      font-weight: 400;
      font-style: normal;
      line-height: 22px;
      color: #27282c;

      @media (min-width: 1300px) {
        font-weight: 400;
        font-size: 22px;
        line-height: 24px;
      }
    }
  }

  .textarea-label {
    @media (min-width: 1300px) {
      grid-column-start: 1;
      grid-column-end: 3;
    }
  }

  textarea {
    width: 100%;
    height: 153px;
    border: none;
    border-bottom: 1px solid #cacaca;
    outline: none;
    font-family: 'Jost', sans-serif;
    font-size: 20px;
    font-weight: 400;
    font-style: normal;
    line-height: 22px;
    color: #27282c;
    background: none;

    @media (min-width: 1300px) {
      height: 135px;
    }

    &::placeholder {
      font-family: 'Jost', sans-serif;
      font-size: 20px;
      font-weight: 400;
      font-style: normal;
      line-height: 22px;
      color: #27282c;

      @media (min-width: 1300px) {
        font-weight: 400;
        font-size: 22px;
        line-height: 24px;
      }
    }
  }

  input[type='file'] {
    display: none;
  }

  .form_attach-file-button-wrapper {
    @media (min-width: 1300px) {
      display: flex;
      padding-top: 15px;
      line-height: 1px;
      grid-column-start: 1;
      grid-column-end: 3;
    }
  }

  .form_attach-file-button {
    position: relative;
    border-bottom: 1px dotted #27282c;
    height: max-content;
    margin-left: 40px;
    font-size: 20px;
    line-height: 25px;
    cursor: pointer;

    @media (min-width: 1300px) {
      font-weight: 400;
      font-size: 22px;
      line-height: 24px;
      margin-top: 20px;
    }

    &::before {
      content: '';
      width: 22px;
      height: 25px;
      background-image: url('../../../assets/img/attach-file-icon-black.svg');
      position: absolute;
      top: 4px;
      left: -35px;
    }
  }

  .attatched-file-wrapper {
    align-items: flex-end;
    flex-wrap: nowrap;
    display: flex;
    padding-left: 20px;
    font-weight: 400;
    font-size: 22px;
    line-height: 24px;
  }

  .form_condition-label {
    @media (min-width: 1300px) {
      grid-column-start: 1;
      grid-column-end: 3;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 32px;
      margin-top: 30px;
    }

    input {
      display: none;

      &:checked+span::after {
        content: '';
        width: 20px;
        height: 20px;
        position: absolute;
        top: -2px;
        left: -38px;
        display: block;
        background-color: #27282c;
      }
    }

    .agrBtn-text {
      position: relative;
      display: block;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
      margin-left: 38px;

      &::before {
        display: block;
        content: '';
        width: 22px;
        height: 22px;
        position: absolute;
        top: -4px;
        left: -40px;
        border: 1px solid #27282c;
      }

      &::after {
        content: '';
        width: 20px;
        height: 20px;
        position: absolute;
        top: -2px;
        left: -38px;
        display: none;
      }
    }
  }
}

.form_inputs-wrapper--dark {
  display: grid;
  grid-template-columns: 1fr;
  gap: 30px;
  color: #ffffff;

  @media (min-width: 1300px) {
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    column-gap: 64px;
  }

  input[type='text'],
  input[type='tel'],
  input[type='email'] {
    width: 100%;
    height: 42px;
    border: none;
    border-bottom: 1px solid #494949;
    padding: 0 0 20px 0;
    outline: none;
    font-family: 'Jost', sans-serif;
    font-size: 20px;
    font-weight: 400;
    font-style: normal;
    line-height: 22px;
    color: #ffffff;
    background: none;

    @media (min-width: 1300px) {
      margin-bottom: 40px;
    }

    &::placeholder {
      font-family: 'Jost', sans-serif;
      font-size: 20px;
      font-weight: 400;
      font-style: normal;
      line-height: 22px;
      color: #ffffff;

      @media (min-width: 1300px) {
        font-weight: 400;
        font-size: 22px;
        line-height: 24px;
      }
    }
  }

  .textarea-label {
    @media (min-width: 1300px) {
      grid-column-start: 1;
      grid-column-end: 3;
    }
  }

  textarea {
    width: 100%;
    height: 153px;
    border: none;
    border-bottom: 1px solid #494949;
    outline: none;
    font-family: 'Jost', sans-serif;
    font-size: 20px;
    font-weight: 400;
    font-style: normal;
    line-height: 22px;
    color: #ffffff;
    background: none;

    @media (min-width: 1300px) {
      height: 135px;
    }

    &::placeholder {
      font-family: 'Jost', sans-serif;
      font-size: 20px;
      font-weight: 400;
      font-style: normal;
      line-height: 22px;
      color: #ffffff;

      @media (min-width: 1300px) {
        font-weight: 400;
        font-size: 22px;
        line-height: 24px;
      }
    }
  }

  input[type='file'] {
    display: none;
  }

  .form_attach-file-button-wrapper {
    @media (min-width: 1300px) {
      display: flex;
      padding-top: 15px;
      grid-column-start: 1;
      grid-column-end: 3;
    }
  }

  .form_attach-file-button {
    position: relative;
    border-bottom: 1px dotted #ffffff;
    height: max-content;
    margin-left: 40px;
    font-size: 20px;
    line-height: 25px;
    cursor: pointer;

    @media (min-width: 1300px) {
      font-weight: 400;
      font-size: 22px;
      line-height: 24px;
      margin-top: 20px;
    }

    &::before {
      content: '';
      width: 22px;
      height: 25px;
      background-image: url('../../../assets/img/attach-file-icon-white.svg');
      position: absolute;
      top: 4px;
      left: -35px;
    }
  }

  .attatched-file-wrapper {
    display: flex;
    font-size: 12px;
    margin: 20px 0;

    @media (min-width: 1300px) {
      margin: 0;
      padding-left: 20px;
      font-weight: 400;
      font-size: 22px;
      line-height: 24px;
      align-items: flex-end;
    }
  }

  .form_condition-label {
    @media (min-width: 1300px) {
      grid-column-start: 1;
      grid-column-end: 3;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 32px;
      margin-top: 30px;
    }

    input {
      display: none;

      &:checked+span::after {
        content: '';
        width: 20px;
        height: 20px;
        position: absolute;
        top: -2px;
        left: -38px;
        display: block;
        background-color: #ffffff;
      }
    }

    .agrBtn-text {
      position: relative;
      display: block;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
      margin-left: 38px;

      &::before {
        display: block;
        content: '';
        width: 22px;
        height: 22px;
        position: absolute;
        top: -4px;
        left: -40px;
        border: 1px solid #ffffff;
      }

      &::after {
        content: '';
        width: 20px;
        height: 20px;
        position: absolute;
        top: -2px;
        left: -38px;
        display: none;
      }
    }
  }
}

.form_submit-button {
  width: 100%;
  height: 74px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px 64px;
  border: 1px solid #27282c;
  border-radius: 2px;
  background-color: #ffffff;
  font-family: 'Jost';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 26px;
  color: #27282c;

  transition-property: border, background-color, color;
  transition-duration: 0.5s;

  @media (min-width: 1300px) {
    grid-column-start: 1;
    grid-column-end: 3;
    margin-top: 35px;
  }
}

.form_submit-button--dark {
  width: 100%;
  height: 74px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px 64px;
  border: 1px solid #ffffff;
  border-radius: 2px;
  background-color: #27282c;
  font-family: 'Jost';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 26px;
  color: #ffffff;

  transition-property: border, background-color, color;
  transition-duration: 0.5s;

  @media (min-width: 1300px) {
    grid-column-start: 1;
    grid-column-end: 3;
    margin-top: 35px;
  }
}

.form_capcha-wrapper {
  width: 284px;

  @media (min-width: 340px) {
    width: auto;
  }

  @media (min-width: 1300px) {
    display: flex;
    justify-content: flex-end;
    padding-top: 10px;
  }
}

.g-recaptcha {
  transform: scale(0.9);

  @media (min-width: 340px) {
    transform: scale(1);
  }
}

.form_phone-label {
  display: flex;

  & select {
    appearance: none;
    font-size: 20px;
    font-weight: 400;
    font-style: normal;
    outline: none;

    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  & select {
    height: 42px;
    margin: -10px 20px 0 0;
    background: none;
    border: none;
  }

  &--dark {
    display: flex;

    & select {
      appearance: none;
      font-size: 20px;
      font-weight: 400;
      font-style: normal;
      outline: none;

      -ms-overflow-style: none;
      scrollbar-width: none;

      & option {
        background-color: #27282c;
      }

      &::-webkit-scrollbar {
        display: none;
      }
    }

    & select {
      height: 42px;
      margin: -10px 20px 0 0;
      background: none;
      border: none;
      color: #ffffff;
    }
  }
}

.agrBtn {
  cursor: pointer;
  text-decoration: underline;
}