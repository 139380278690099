.partners-section {
  padding: 0 0 150px 0;

  @media (min-width: 1300px) {
    padding: 0 0 200px 0;
    // margin-bottom: 130px;
  }
}

.partners-section_title-wrapper {
  margin-bottom: 30px;

  @media (min-width: 1300px) {
    margin-bottom: 44px;
  }
}

.partners-section_partners-list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: 33% 33% 33%;
  row-gap: 24px;

  @media (min-width: 1300px) {
    grid-template-columns: max-content max-content max-content max-content max-content;
    justify-content: space-between;
    column-gap: 67px;
    row-gap: 44px;
  }
}

.partners-section_partners-item {
  display: flex;
  justify-content: center;

  @media (min-width: 1300px) {
    // justify-content: flex-start;
    justify-content: center;
  }
}

.partners-item_image {
  height: 80%;
  width: 80%;
  object-fit: contain;

  @media (min-width: 1300px) {
    height: 80px;
    max-width: 120px;
  }
}

.slider-container {
  display: flex;
  flex-direction: column;
  @media (min-width: 740px) {
    flex-direction: row-reverse;
  }
}
.project-text {
  font-family: 'St.Sign', serif;
  margin: 40px 0 20px;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.button-container {
  display: flex;
  width: 100%;
  justify-content: center;
}
.project-button {
  cursor: pointer;
  font-family: 'St.Sign', serif;
  border-radius: 6px;
  width: 100%;
  max-width: 300px;
  height: 60px;
  background: #ffffff;
  color: #27282c;
  font-size: 24px;
  margin-top: 20px;
  border: 1px solid #27282c;
  @media (min-width: 740px) {
    margin-top: 60px;
  }
}

.white {
  border: 1px solid #27282c;
  background: transparent;

}