.header {
  width: 100%;
  padding: 30px 30px 30px 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (min-width: 480px) {
    padding: 30px 60px 30px 60px;
  }

  @media (min-width: 740px) {
    padding: 30px 100px 30px 100px;
  }

  @media (min-width: 1300px) {
    padding: 30px 150px 30px 150px;
    justify-content: flex-start;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
  }

  @media (min-width: 1550px) {
    padding: 30px 200px 30px 250px;
  }

  @media (min-width: 1850px) {
    padding: 30px 200px 30px 200px;
  }
}

.header_logo {
  width: 105px;
  height: 33px;

  @media (min-width: 1300px) {
    width: 142px;
    height: 44px;
  }
}

.menu-logo {
  width: 153px;
  height: 49px;
  margin-bottom: 16px;

  @media (min-width: 1300px) {
    display: none;
  }
}

.header_control-panel {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
  display: none;
  flex-direction: column;
  z-index: 1001;
  align-items: center;
  padding: 68px 20px 0 20px;

  transition-property: background-color;
  transition-duration: 0.5s;

  @media (min-width: 1300px) {
    position: static;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 0 0 58px;
    gap: 10px;
  }
}

.header_control-panel--dark {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #27282c;
  display: none;
  flex-direction: column;
  z-index: 1001;
  align-items: center;
  padding: 68px 20px 0 20px;

  transition-property: background-color;
  transition-duration: 0.5s;

  @media (min-width: 1300px) {
    position: static;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 0 0 58px;
    gap: 10px;
  }
}

.header_control-panel--opened {
  display: flex;
}

.header_mobile-menu-button {
  @media (min-width: 1300px) {
    display: none;
  }
}

.header_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media (min-width: 1300px) {
    flex-direction: row;
    justify-content: flex-end;
    gap: 23px;
  }
}

.header_theme-toggler {
  margin-bottom: 93px;

  @media (min-width: 1300px) {
    margin-bottom: 0;
  }
}

.header_lang-toggler {
  order: 2;

  @media (min-width: 1300px) {
    order: 0;
  }
}

.header_phone-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 32px;

  @media (min-width: 1300px) {
    margin-left: 40px;
    margin-bottom: 0;
  }
}

.header_phone {
  text-decoration: none;
  color: #27282c;

  @media (max-width: 1300px) {
    font-weight: 400;
    font-size: 28px;
    line-height: 31px;
  }

  transition-property: color;
  transition-duration: 0.5s;
}

.header_phone--dark {
  text-decoration: none;
  color: #ffffff;

  @media (max-width: 1300px) {
    font-weight: 400;
    font-size: 28px;
    line-height: 31px;
  }

  transition-property: color;
  transition-duration: 0.5s;
}

.contact-us-button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 93px;
  width: 100%;

  @media (min-width: 1300px) {
    margin-left: 20px;
    margin-bottom: 0;
    width: 175px;
    justify-content: flex-end;
  }
}

.contact-us-button {
  text-decoration: none;
  color: rgba(39, 40, 44, 1);
  padding: 12px 24px;
  border: 1px solid #27282c;
  border-radius: 2px;

  @media (max-width: 1300px) {
    width: 100%;
    text-align: center;
    font-weight: 400;
    font-size: 24px;
    line-height: 26px;
    padding: 20px;
    max-width: 500px;
  }

  transition-property: color, border;
  transition-duration: 0.5s;
}

.contact-us-button--dark {
  text-decoration: none;
  color: #ffffff;
  padding: 12px 24px;
  border: 1px solid #ffffff;
  border-radius: 2px;

  @media (max-width: 1300px) {
    width: 100%;
    text-align: center;
    font-weight: 400;
    font-size: 24px;
    line-height: 26px;
    padding: 20px;
    max-width: 500px;
  }

  transition-property: color, border;
  transition-duration: 0.5s;
}

.close-button {
  background: none;
  border: none;
  padding: 0;
  position: absolute;
  top: 20px;
  right: 20px;

  @media (min-width: 1300px) {
    display: none;
  }
}
