.footpartners-section {
  padding: 0 0 30px 0;
}

.footpartners-section_footpartners-list {
  padding: 0;
  margin: 0;
  list-style: none;
}

.footpartners-item {
  margin-bottom: 8px;
}

.footpartners-item_image {
  max-height: 36px;
  max-width: 243px;
}

