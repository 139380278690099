.agreement_bg {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 1002;
  backdrop-filter: blur(5px);
  padding: 100px 50px;
  display: none;

  @media (min-width: 1300px) {
    padding: 100px 200px;
  }
}

.agreement_bg--opened {
  display: block;
}

.agreement_container {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 300px;
  background-color: #fff;
  overflow-x: scroll;
  padding: 35px 15px;

  @media (min-width: 600px) {
    padding: 85px 60px;
  }

  @media (min-width: 1300px) {
    padding: 85px 120px;
  }
}

.agreement_container--dark {
  background-color: #27282c;
}

.agreement_close-button {
  position: absolute;

  right: 15px;
  top: 15px;

  img {
    width: 20px;
    height: 20px;
    fill: aqua;
  }

  @media (min-width: 600px) {
    right: 50px;
    top: 50px;

    img {
      width: 38px;
      height: 38px;
      fill: aqua;
    }
  }
}

.agreement_title {
  font-family: 'Oranienbaum';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 49px;
  white-space: pre-line;

  @media (min-width: 600px) {
    font-size: 44px;
    line-height: 48px;
  }
}

.agreement_text-container {
  font-family: 'Jost';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;

  @media (min-width: 600px) {
    font-size: 24px;
    line-height: 26px;
  }
}

.agreement_bold {
  font-weight: 900;
}

.agreement_data-list {
  list-style-type: '·';
}

.agreement_data-item {
  padding-left: 10px;
}

.agreement_aim-list {
  padding-left: 20px;
  list-style-type: '–';
}
