.title-section {
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  //padding-top: 30px;
  background-image: url("../../../assets/img/backgrounds/background-mobile.svg");
  height: 150px;
  padding: 0 15px;
  background-position: center;
  font-size: 35px;
  background-repeat: no-repeat;
  background-size: cover;
  @media (min-width: 480px) {
    padding: 0 60px;
  }

  @media (min-width: 740px) {
    padding: 0 100px;
  }

  @media (min-width: 1300px) {
    padding: 170px 150px 0 150px;
  }

  @media (min-width: 1550px) {
    padding: 170px 200px 0 250px;
  }

  @media (min-width: 1850px) {
    padding: 170px 200px 0 400px;
  }
  @media (min-width: 480px) {
    height: 250px;
  }

  @media (min-width: 740px) {
    height: 350px;
    background-image: url("../../../assets/img/backgrounds/background.svg");

  }
  @media (min-width: 1300px) {
    //background-image: url("../../../assets/img/backgrounds/background.svg");
    height: 500px;
  }
}

.title-section_title-wrapper {
  z-index: 1000;
  margin-top: 5px;
  //padding-left: 40px;

  @media (min-width: 1300px) {
    margin-top: 0;
    padding-top: 0;
    //margin-bottom: 93px;
    //padding-left: 150px;
  }
}

.title-section_title {
  margin: 0;
  font-family: 'Oranienbaum', serif;
  font-size: 35px;
  font-weight: 400;
  line-height: 38.5px;
  white-space: pre-line;

  @media (min-width: 480px) {
    font-size: 45px;
    line-height: 45px;
  }

  @media (min-width: 740px) {
    font-size: 54px;
    line-height: 58px;
  }

  @media (min-width: 1300px) {
    font-size: 74px;
    line-height: 87px;
    width: 810px;
  }
}

.title-section_bg-wrapper {
  //display: flex;
  //justify-content: flex-end;
  //margin-top: -100px;
  max-height: 500px;
  position: absolute;
  z-index: 0;

  @media (min-width: 480px) {
    //margin-top: -160px;
  }

  @media (min-width: 740px) {
    //margin-top: -100px;

  }

  @media (min-width: 1300px) {
    //margin-top: -320px;
  }
}

.title-section_bg-earth {
  margin-right: -18px;

  @media (min-width: 480px) {
    margin-right: -60px;
  }

  @media (min-width: 740px) {
    margin-right: -100px;
  }

  @media (min-width: 1300px) {
    margin-right: 25px;
  }
}
