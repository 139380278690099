.content {
  padding: 0 18px;
  width: 100%;
  overflow: hidden;

  @media (min-width: 480px) {
    padding: 0 60px;
  }

  @media (min-width: 740px) {
    padding: 0 100px;
  }

  @media (min-width: 1300px) {
    padding: 170px 150px 0 150px;
  }

  @media (min-width: 1550px) {
    padding: 170px 200px 0 250px;
  }

  @media (min-width: 1850px) {
    padding: 170px 200px 0 400px;
  }
}
